import React from 'react'
import Helpers from '../../../../Config/Helpers'

function Card({ subItem, onSelectFile, fileInputRef, handleImageClick, handleFeatureChange, padding, margin, rowIndex,
    columnIndex,
    itemIndex }) {
    const { top: topPadding, right: rightPadding, bottom: bottomPadding, left: leftPadding } = padding;
    const { top: topMargin, right: rightMargin, bottom: bottomMargin, left: leftMargin } = margin;
    return (
        <div
            className="card-container"
            style={{
                borderRadius: `${subItem.style.borderRadius}px`,
                margin: `${subItem.style.margin.top}px ${subItem.style.margin.right}px ${subItem.style.margin.bottom}px ${subItem.style.margin.left}px`,
                padding: `${subItem.style.padding.top}px ${subItem.style.padding.right}px ${subItem.style.padding.bottom}px ${subItem.style.padding.left}px`,
                textAlign: subItem.style.alignment,
                background: subItem.style.background,
                boxShadow: subItem.style.boxShadow
            }}
        >
            {/* Image */}
            <div className="card-image-container">
                <input
                    type="file"
                    className="hidden"
                    onChange={(e) => onSelectFile(rowIndex, columnIndex, itemIndex, e)}

                    ref={fileInputRef}
                />
                {subItem.preview || subItem.content ? (
                    <img
                        src={
                            subItem.preview
                                ? subItem.preview // Show the preview if a new image is selected
                                : typeof subItem.content === 'string'
                                    ? `${Helpers.basePath}/storage/${subItem.content}` // Show stored image from the server
                                    : URL.createObjectURL(subItem.content) // Show new uploaded image
                        }
                        className="cursor-pointer mx-auto"
                        style={{
                            width: `${subItem.style.size.width}%`,
                            height: `${subItem.style.size.height}%`,
                            borderRadius: `${subItem.style.radius}px`,
                            padding: `${subItem.style.padding.top}px ${subItem.style.padding.right}px ${subItem.style.padding.bottom}px ${subItem.style.padding.left}px`,
                            margin: `${subItem.style.margin.top}px ${subItem.style.margin.right}px ${subItem.style.margin.bottom}px ${subItem.style.margin.left}px`,
                        }}
                        onClick={handleImageClick}
                        alt=""
                    />
                ) : (
                    <img
                        src="/assets/image.png"
                        className="w-[20%] text-center cursor-pointer mx-auto invert"
                        onClick={handleImageClick}
                        style={{
                            padding: `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`,
                            margin: `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`,
                        }}
                        alt=""
                    />
                )}
            </div>

            {/* Heading */}
            <div className="card-heading-container">
                <input
                    type="text" autoComplete="off"
                    className={`w-full h-full outline-none text-center font-bold py-2 px-4 rounded-xl transition duration-300 placeholder-gray-300 ${subItem.style.headingStyle}`}
                    placeholder="Enter Heading Text Here"
                    onChange={(e) => handleFeatureChange(rowIndex, columnIndex, itemIndex, e, 'heading')}
                    name="heading"
                    value={subItem.heading || ''}
                    style={{
                        color: subItem.style.color || 'white',
                        borderRadius: `${subItem.style.radius}px`,
                        padding: `${subItem.style.padding.top}px ${subItem.style.padding.right}px ${subItem.style.padding.bottom}px ${subItem.style.padding.left}px`,
                        background: 'none'
                    }}
                />
            </div>

            {/* Paragraph */}
            <div className="card-paragraph-container">
                <textarea
                    placeholder="Enter Free Text Here"
                    className="w-full outline-none"
                    onChange={(e) => handleFeatureChange(rowIndex, columnIndex, itemIndex, e, 'paragraph')}
                    name="paragraph"
                    value={subItem.paragraph || ''}
                    style={{
                        color: subItem.style.color || 'white',
                        padding: `${subItem.style.padding.top}px ${subItem.style.padding.right}px ${subItem.style.padding.bottom}px ${subItem.style.padding.left}px`,
                        margin: `${subItem.style.margin.top}px ${subItem.style.margin.right}px ${subItem.style.margin.bottom}px ${subItem.style.margin.left}px`,
                        textAlign: subItem.style.alignment,
                        background: 'none',
                        fontSize: subItem.style.fontSize
                    }}
                ></textarea>
            </div>
        </div>
    )
}

export default Card
