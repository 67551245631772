import React, { createContext, useContext, useState } from 'react';

// Create the context
const RowColumnContext = createContext();

export const RowColumnProvider = ({ children }) => {
    const [rowColumnData, setRowColumnData] = useState({
        rowIndex: null,
        columnIndex: null,
        itemIndex: null,
        part: null,
    });

    return (
        <RowColumnContext.Provider value={{ rowColumnData, setRowColumnData }}>
            {children}
        </RowColumnContext.Provider>
    );
};


// Create a custom hook to use the context
export const useRowColumnContext = () => {
    return useContext(RowColumnContext);
};
