import React, { useState } from 'react'
import HelmetWrapper from '../../../Config/HelmetWrapper'
import Header from './Header/Header'
import NewPageScreen from './NewPageScreen'
import { useDrag } from 'react-dnd';
import DraggableButton from './Buttons/DraggableButton';
import DraggableImage from './Buttons/DraggableImage';
import StyleSidebar from './StyleSidebar';
import DragabbleSingleButton from './Buttons/DragabbleSingleButton';
import DraggableTextArea from './Buttons/DraggableTextArea';
import DragabbleCardButton from './Buttons/DragabbleCardButton';

function OptionSidebar({ type }) {
    const [container, setContainer] = useState(false)
    const [grid , setGrid] = useState(false)
    const [heading, setHeading] = useState(false)
    const [style, setStyle] = useState(true)
    const [headingStyle, setHeadingStyle] = useState('');
    const [colorStyle, setColorStyle] = useState('white')
    const [bgColor, setbgColor] = useState('black')
    const [padding, setPadding] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
    const [margin, setMargin] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
    const [align, setAlign] = useState('center');
    const [imagesize, setImageSize] = useState({ width: 0, height: 0 })
    const [imageradius, setImageRadius] = useState({ radius: 0 })
    const initialSelectedElement = {
        rowIndex: null,
        columnIndex:  null,
        itemIndex:null,
        part: null,
    };
    const [selectedElement, setSelectedElement] = useState(initialSelectedElement);
    // console.log('margin',margin)
    const handleSection = () => {
        setStyle(!style)
    }
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'ITEM',
        item: { type },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const handleClick = () => {
        setHeading(!heading)
    }
    const handleContainer = () => {
        setContainer(!container)
    }
    const handleGrid = () => {
        setGrid(!grid)
    }
    return (
        <>
            <HelmetWrapper />
            <Header />
            <div className="flex flex-row bg-white">
                <div className='basis-[20%] p-5 bg-slate-50 h-[93vh] overflow-auto shadow-md'>

                    <div className='flex justify-around'>
                        <h3 className={`mt-5 text-center font-bold mb-5 cursor-pointer bg-slate-100 p-5 rounded ${style ? 'border-2' : ''}`} onClick={handleSection} >Elements</h3>
                        <h3 className={`mt-5 text-center font-bold mb-5 cursor-pointer bg-slate-100 p-5 rounded ${style ? '' : 'border-2'}`} onClick={handleSection}>Style</h3>
                    </div>
                    <hr />
                    {style ?
                        <>
                            <span className='text-md font-bold'><i className="fa-sharp fa-solid fa-caret-down mr-3 mt-3"></i> Layout</span>
                            <div className='flex justify-around align-items-center mb-4'>
                                <div className='mt-8 border-2 border-gray-300 w-[50%] text-center m-1 shadow-xs rounded'>
                                    <i className="fa-sharp fa-light fa-container-storage pt-3" style={{ fontSize: "1.5rem" }}></i> <br />
                                    <button className='btn' onClick={handleContainer}>Container</button>
                                </div>
                                <div className='mt-8  border-2 border-gray-300 w-[50%] text-center m-1 shadow-xs rounded'>
                                    <i className="fa-regular fa-grid pt-3 " style={{ fontSize: "1.5rem" }}></i> <br />
                                    <button className='btn' onClick={handleGrid}>Grid</button>
                                </div>
                            </div>
                            <hr />
                            <span className='text-md font-bold'><i className="fa-sharp fa-solid fa-caret-down mr-3 mt-5"></i> Basic</span>
                            <div className='flex justify-around align-items-center'>
                                <DraggableButton type="heading" onClick={() => {
                                    // handleSection();
                                    // handleClick();
                                }} 
                                // handleClick={handleClick} 
                                />
                                <DraggableImage type="image" onClick={() => {
                                    // handleSection();
                                    // handleClick();
                                }} />
                            </div>
                            <div className='flex justify-around align-items-center'>
                                {/* <DraggableCard type="card" /> */}
                                <DraggableTextArea type="textarea" />
                                <DragabbleSingleButton type="button" />
                            </div>
                            <div className='flex justify-around align-items-center'>
                                <DragabbleCardButton type="card" />
                            </div>
                        </>
                        : <StyleSidebar onHeadingChange={setHeadingStyle} setColorStyle={setColorStyle} setPadding={setPadding} padding={padding} margin={margin} setMargin={setMargin} setAlign={setAlign} setImageSize={setImageSize} imagesize={imagesize} setImageRadius={setImageRadius} selectedElement={selectedElement} rowIndex={0} setbgColor={setbgColor}/>}
                </div>
                <NewPageScreen heading={heading} click={handleClick} container={container} handleContainer={handleContainer} headingStyle={headingStyle} colorStyle={colorStyle} padding={padding} margin={margin} align={align} imagesize={imagesize} imageradius={imageradius} selectedElement={selectedElement} setPadding={setPadding} setMargin={setMargin} 
                    setSelectedElement={setSelectedElement} bgColor={bgColor} setImageRadius={setImageRadius} handleGrid={handleGrid} grid={grid} setImageSize={setImageSize}/>
            </div>
        </>
    )
}

export default OptionSidebar