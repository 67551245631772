import React, { useState } from 'react'
import ImageStylebar from './ImageStylebar'
import { usePage } from '../../../layouts/PageContext';

function StyleSidebar({ onHeadingChange, setColorStyle, setPadding, setMargin, setAlign, setImageSize, setImageRadius, selectedElement, setbgColor, padding, margin, imagesize }) {
    const [headingStyle, setHeadingStyle] = useState(false)
    const { formData, setFormData } = usePage();

    const handleHeadingStyle = () => {
        setHeadingStyle(!headingStyle)
    }

    const handleChange = (event) => {
        const { value } = event.target;
    
        if (
            selectedElement.rowIndex !== null &&
            selectedElement.columnIndex !== null &&
            selectedElement.itemIndex !== null
        ) {
            const rows = [...formData.rows];
            const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
    
            let item;
    
            // Check if we are editing an existing page (column.data exists)
            if (column.data) {
                const columnData = JSON.parse(column.data);
                item = columnData.items[selectedElement.itemIndex] || {};
                item.style = item.style || {};
                item.style.headingStyle = value;
    
                // Update column data with modified items array
                column.data = JSON.stringify({ ...columnData, items: columnData.items });
            } else {
                // For new pages, modify directly in column.items
                item = column.items[selectedElement.itemIndex] || {};
                item.style = item.style || {};
                item.style.headingStyle = value;
            }
    
            setFormData({ ...formData, rows });
        } else {
            console.error('Selected element indices are not set correctly.');
        }
    };
    


    const onColorChange = (event) => {
        const { value } = event.target;
        setColorStyle(value);

        if (
            selectedElement.rowIndex !== null &&
            selectedElement.columnIndex !== null &&
            selectedElement.itemIndex !== null
        ) {
            const rows = [...formData.rows];
            const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
            // const item = column.items[selectedElement.itemIndex];
            let item 

            if(column.data){
                const columnData = JSON.parse(column.data)
                item = columnData.items[selectedElement.itemIndex] || {}
                item.style = item.style || {}
                item.style.color = value
                column.data =  JSON.stringify({...columnData , items:columnData.items})
            } else {
                item = column.items[selectedElement.itemIndex] || {}
                item.style = item.style || {}
                item.style.color = value
            }

            // item.style.color = value;
            setFormData({ ...formData, rows });
        } else {
            console.error('Selected element indices are not set correctly.');
        }
    };

    const onBgColorChange = (event) => {
        const { value } = event.target;
        setbgColor(value);

        if (
            selectedElement.rowIndex !== null &&
            selectedElement.columnIndex !== null &&
            selectedElement.itemIndex !== null
        ) {
            const rows = [...formData.rows];
            const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
            // const item = column.items[selectedElement.itemIndex];
            let item 

            if(column.data){
                const columnData = JSON.parse(column.data)
                item = columnData.items[selectedElement.itemIndex] || {}
                item.style = item.style || {}
                item.style.background = value
                column.data =  JSON.stringify({...columnData , items:columnData.items})
            } else {
                item = column.items[selectedElement.itemIndex] || {}
                item.style = item.style || {}
                item.style.background = value
            }
            // item.style.background = value;
            setFormData({ ...formData, rows });
        } else {
            console.error('Selected element indices are not set correctly.');
        }
    };

    const onPaddingChange = (event) => {
        const { value, dataset } = event.target;
        const side = dataset.side;
      
        // Update local state for padding
        setPadding((prevPadding) => ({
          ...prevPadding,
          [side]: parseInt(value, 10) || 0,
        }));
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
      
          if (column.data) {
            // Handle editing case, parse column.data
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
            
            // Update the padding in the style object
            item.style.padding = {
              ...item.style.padding,
              [side]: parseInt(value, 10) || 0,
            };
      
            // Update column.data with the new style
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            // Handle new data case
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update the padding in the style object
            item.style.padding = {
              ...item.style.padding,
              [side]: parseInt(value, 10) || 0,
            };
          }
      
          // Update formData
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      

      const onMarginChange = (event) => {
        const { value, dataset } = event.target;
        const side = dataset.side;
      
        // Update local state for margin
        setMargin((prevMargin) => ({
          ...prevMargin,
          [side]: parseInt(value, 10) || 0,
        }));
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
      
          if (column.data) {
            // Handle editing case, parse column.data
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update the margin in the style object
            item.style.margin = {
              ...item.style.margin,
              [side]: parseInt(value, 10) || 0,
            };
      
            // Update column.data with the new style
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            // Handle new data case
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update the margin in the style object
            item.style.margin = {
              ...item.style.margin,
              [side]: parseInt(value, 10) || 0,
            };
          }
      
          // Update formData
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      

      const onSizeChange = (event) => {
        const { value, dataset } = event.target;
        const side = dataset.side;
      
        // Update local state for image/card size
        setImageSize((prevSize) => ({
          ...prevSize,
          [side]: parseInt(value, 10) || 0,
        }));
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
      
          if (column.data) {
            // Handle editing case, parse column.data
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update size for image or card
            if (item.type === 'image' || selectedElement.part === 'image') {
              item.style.size = {
                ...item.style.size,
                [side]: parseInt(value, 10) || 0,
              };
            } else if (item.type === 'card' && selectedElement.part === 'card') {
              item.cardStyle = item.cardStyle || {};
              item.cardStyle.size = {
                ...item.cardStyle.size,
                [side]: parseInt(value, 10) || 0,
              };
            }
      
            // Update column data with new size
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            // Handle new data case
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            if (item.type === 'image' || selectedElement.part === 'image') {
              item.style.size = {
                ...item.style.size,
                [side]: parseInt(value, 10) || 0,
              };
            } else if (item.type === 'card' && selectedElement.part === 'card') {
              item.cardStyle = item.cardStyle || {};
              item.cardStyle.size = {
                ...item.cardStyle.size,
                [side]: parseInt(value, 10) || 0,
              };
            } else {
              item.style.size = {
                ...item.style.size,
                [side]: parseInt(value, 10) || 0,
              };
            }
          }
      
          // Update formData
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      

    const onAlignChange = (event) => {
        const alignment = event.currentTarget.dataset.align;
        setAlign(alignment);

        if (
            selectedElement.rowIndex !== null &&
            selectedElement.columnIndex !== null &&
            selectedElement.itemIndex !== null
        ) {
            const rows = [...formData.rows];
            const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
            // const item = column.items[selectedElement.itemIndex];
            let item 

            if(column.data){
                const columnData = JSON.parse(column.data)
                item = columnData.items[selectedElement.itemIndex] || {}
                item.style = item.style || {}
                item.style.alignment = alignment
                column.data =  JSON.stringify({...columnData , items:columnData.items})
            } else {
                item = column.items[selectedElement.itemIndex] || {}
                item.style = item.style || {}
                item.style.alignment = alignment
            }

            // item.style.alignment = alignment;
            setFormData({ ...formData, rows });
        } else {
            console.error('Selected element indices are not set correctly.');
        }
    };

    const onRadiusChange = (event) => {
        const { value } = event.target;
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
          if (column.data) {
            // Handle editing case, parse column.data
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
            
            // Update border radius for editing
            item.style.borderRadius = parseInt(value, 10) || 0;
      
            // Update column data
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            // Handle new data case
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update border radius for new data
            item.style.borderRadius = parseInt(value, 10) || 0;
          }
      
          // Update formData with the modified rows
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };

      const onFontChange = (event) => {
        const { value } = event.target;
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
          if (column.data) {
            // Handle editing case, parse column.data
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
            
            // Update border radius for editing
            item.style.fontSize = parseInt(value, 10) || 0;
      
            // Update column data
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            // Handle new data case
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update border radius for new data
            item.style.fontSize = parseInt(value, 10) || 0;
          }
      
          // Update formData with the modified rows
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      

      const handleBoxShadowChange = (event) => {
        const { value } = event.target;
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
          if (column.data) {
            // Handle editing case, parse column.data
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update box shadow for editing
            item.style.boxShadow = value;
      
            // Update column data
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            // Handle new data case
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
      
            // Update box shadow for new data
            item.style.boxShadow = value;
          }
      
          // Update formData with the modified rows
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      

    // const column = formData.rows[selectedElement.rowIndex]?.columns[selectedElement.columnIndex];
    // const item = column?.items[selectedElement.itemIndex] || {};
    // item.style = item.style || {};


    const column = formData.rows[selectedElement.rowIndex]?.columns[selectedElement.columnIndex];
    let item = {};

    // Check if we are in edit mode (where column.data exists)
    if (column?.data) {
        const columnData = JSON.parse(column.data); // Parse the column.data JSON
        item = columnData.items[selectedElement.itemIndex] || {}; // Get the item from columnData
    } else {
        item = column?.items[selectedElement.itemIndex] || {}; // Fallback to direct items array for new page
    }

    // Ensure style object exists
    item.style = item.style || {};

    // Now you can proceed with using item.style in the styleSidebar component

    // const cardStyle = column?.cardStyle || {};


    return (
        <div>
            <div>
                <span className='text-md font-bold cursor-pointer' onClick={handleHeadingStyle}><i className="fa-sharp fa-solid fa-caret-down mr-3 mt-3"></i> Font Style</span>
                <>
                    <h3 className='mt-5 font-semibold'>Select a Heading</h3>
                    <select className='px-5 py-2 w-full mt-4 outline-none rounded shadow-sm' onChange={handleChange}>
                        <option>Select Option</option>
                        <option className='h1' value='h1'>Heading 1</option>
                        <option className='h2' value='h2'>Heading 2</option>
                        <option className='h3' value='h3'>Heading 3</option>
                    </select>
                    <div className='flex justify-between w-full mt-5'>
                        <div className='w-full'>
                            <label className='font-bold'>Font Size : </label>
                            <input
                                type='number'
                                className='p-2 m-2 rounded shadow-sm w-[100%] outline-none'
                                placeholder='0px'
                                onChange={onFontChange}
                                value={item.style.fontSize || 0}
                            />

                        </div>
                    </div>
                    <h3 className='mt-5 font-semibold'>Select a Color</h3>
                    <input type='color' className='mt-4 cursor-pointer ' onChange={onColorChange} />

                    <h3 className='mt-5 font-semibold'>Select a Bg Color</h3>
                    <input type='color' className='mt-4 cursor-pointer' onChange={onBgColorChange} />

                    <h3 className='mt-5 font-semibold'>Select a Box Shadow</h3>
                    <select onChange={handleBoxShadowChange} className='px-5 py-2 w-full mt-4 outline-none rounded shadow-sm'>
                        <option value="none">None</option>
                        <option value="0px 4px 6px  rgba(0, 0, 0, 0.1)">Small</option>
                        <option value="0px 8px 15px rgba(0, 0, 0, 0.2)">Medium</option>
                        <option value="0px 12px 20px rgba(0, 0, 0, 0.3)">Large</option>
                    </select>


                    <h3 className='mt-5 font-semibold'>Padding</h3>
                    <div className='flex justify-between w-full'>
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' data-side='top' onChange={onPaddingChange} value={padding.top} />
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' data-side='right' onChange={onPaddingChange} value={padding.right} />
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' onChange={onPaddingChange} data-side='left' value={padding.left} />
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' onChange={onPaddingChange} data-side='bottom' value={padding.bottom} />
                    </div>

                    <h3 className='mt-5 font-semibold'>Margin</h3>
                    <div className='flex justify-between w-full'>
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' onChange={onMarginChange} data-side='top' value={margin.top} />
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' onChange={onMarginChange} data-side='right' value={margin.right} />
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' onChange={onMarginChange} data-side='left' value={margin.left} />
                        <input type='number' placeholder='0' className='w-full outline-none p-2 m-2 rounded shadow-sm' onChange={onMarginChange} data-side='bottom' value={margin.bottom} />
                    </div>

                    <h3 className='my-5 font-semibold'>Text Alignment</h3>
                    <div className='flex justify-between w-full'>
                        <button className='p-2 m-2 rounded shadow-sm w-full' onClick={onAlignChange} data-align='left'><i className="fa-regular fa-align-left"></i></button>
                        <button className='p-2 m-2 rounded shadow-sm w-full' onClick={onAlignChange} data-align='center'><i className="fa-solid fa-align-center"></i></button>
                        <button className='p-2 m-2 rounded shadow-sm w-full' onClick={onAlignChange} data-align='right'><i className="fa-solid fa-align-right"></i></button>
                    </div>
                    <div className='flex justify-between w-full mt-5'>
                        <div className='w-full'>
                            <label className='font-bold'>Border Radius : </label>
                            <input
                                type='number'
                                className='p-2 m-2 rounded shadow-sm w-[100%] outline-none'
                                placeholder='0px'
                                onChange={onRadiusChange}
                                value={item.style.borderRadius || 0}
                            />

                        </div>
                    </div>
                </>
            </div>
            <ImageStylebar onRadiusChange={onRadiusChange} onSizeChange={onSizeChange} imagesize={imagesize} />
        </div>
    )
}

export default StyleSidebar