import React from 'react'
import Helpers from '../../../../Config/Helpers'

function Image({ subItem, onSelectFile, fileInputRef, handleImageClick, padding, margin }) {
    const { top: topPadding, right: rightPadding, bottom: bottomPadding, left: leftPadding } = padding;
    const { top: topMargin, right: rightMargin, bottom: bottomMargin, left: leftMargin } = margin;
    return (
        <div>
            <>
                <input
                    type="file"
                    className="hidden"
                    onChange={onSelectFile}
                    ref={fileInputRef}
                />
                {subItem.preview || subItem.content ? (
                    <img
                        src={   
                            subItem.preview
                                ? subItem.preview // Show the preview if a new image is selected
                                : typeof subItem.content === 'string'
                                    ? `${Helpers.basePath}/storage/${subItem.content}` // Show stored image from the server
                                    : URL.createObjectURL(subItem.content) // Show new uploaded image
                        }
                        className="cursor-pointer mx-auto"
                        style={{
                            width: `${subItem.style.size?.width || '100'}%`,
                            height: `${subItem.style.size?.height || 'auto'}`,
                            borderRadius: `${subItem.style.borderRadius || 0}px`,
                            padding: `${subItem.style.padding?.top || 0}px ${subItem.style.padding?.right || 0}px ${subItem.style.padding?.bottom || 0}px ${subItem.style.padding?.left || 0}px`,
                            margin: `${subItem.style.margin?.top || 0}px ${subItem.style.margin?.right || 0}px ${subItem.style.margin?.bottom || 0}px ${subItem.style.margin?.left || 0}px`,
                        }}
                        onClick={handleImageClick}
                        alt=""
                    />
                ) : (
                    <img
                        src="/assets/image.png"
                        className="w-[20%] text-center cursor-pointer mx-auto invert"
                        onClick={handleImageClick}
                        style={{
                            padding: `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`,
                            margin: `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`,
                        }}
                        alt=""
                    />
                )}
            </>
        </div>
    )
}

export default Image
