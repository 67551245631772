import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Components/Sidebar'
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Helpers from '../../../../Config/Helpers'
import { Link } from 'react-router-dom';
import PublishStatusDropdown from './PublishStatusDropdown';

function PageList() {
    const [data, setData] = useState([])
    const getData = async () => {
        const response = await axios.get(`${Helpers.apiUrl}page/show`)
        setData(response.data.data)
    }
    const handleDelete = async (id) => {
        try {
            await axios.get(`${Helpers.apiUrl}page/delete/${id}`);
            getData();
        } catch (error) {
            console.error('Error deleting plan', error);
        }
    };
    const MySwal = withReactContent(Swal);
    const deletePage = (id) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
            customClass: {
                confirmButton: "px-3 py-2  text-green-100 bg-red-500 rounded-lg",
                cancelButton: "px-3 py-2  text-green-100 mr-3 bg-green-500 rounded-lg"
            },
            buttonsStyling: false
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
                MySwal.fire({
                    title: "Deleted!",
                    text: "Your data has been deleted.",
                    icon: "success"
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                MySwal.fire({
                    title: "Cancelled",
                    text: "Your data is safe :)",
                    icon: "error"
                });
            }
        });
    };
    const customPages = [
        { id: 'custom-1', name: 'Home', is_publish: 1 },
        { id: 'custom-2', name: 'Download', is_publish: 1 },
        { id: 'custom-3', name: 'FAQs', is_publish: 1 },
        { id: 'custom-4', name: 'Support', is_publish: 1 },
        { id: 'custom-5', name: 'News & Article', is_publish: 1 },
    ]
    const mergedPages = [...customPages, ...data]
    useEffect(() => {
        getData()
    }, [])
    return (
        <div>
            <div id="kt_app_wrapper" className="app-wrapper flex-column flex-row-fluid">
                <Sidebar />
                <div className="card mb-5 mb-xl-8 bg-slate-200" style={{ marginTop: "-4%" }}>
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">All New Pages</span>
                        </h3>
                    </div>
                    <div className="card-body py-3 m-5 rounded bg-gray-100">
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th className="min-w-10px">#</th>
                                        <th className="min-w-650px">Page Name</th>
                                        <th className="min-w-40px text-center">Status</th>
                                        <th className="min-w-40px text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.filter((page) => page.is_custom === '0').map((page, index) => (
                                        <tr key={page.id}>
                                            <td>{index + 1}</td>
                                            <td className="">
                                                <div className="d-flex flex-column w-100 me-2">
                                                    <div className="d-flex flex-start ">
                                                        <span className="text-muted me-2 fs-7 fw-bold">
                                                            <p>{page.name}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="">
                                                <div className="d-flex flex-column w-100 me-2">
                                                    <div className="d-flex flex-center ">
                                                        <span className="text-muted me-2 fs-7 fw-bold">
                                                            <PublishStatusDropdown data={page} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-end flex-shrink-0">
                                                    <Link to={`/admin/newpage/${page.slug}`} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                        <i className="fa-light fa-pencil"></i>
                                                    </Link>
                                                    <Link to={`/${page.slug}`} target='_blank' className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                        <i className="fa-regular fa-eye"></i>
                                                    </Link>
                                                    <button onClick={() => deletePage(page.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                        <i className="fa-light fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageList