import React from 'react';
import DroppableColumn from './DroppableColumn';

const RowSection = ({
  rows,
  onRemoveRow,
  onDropItem,
  headingStyle,
  heading,
  colorStyle,
  setImageRadius,
  padding,
  bgColor,
  setImageSize,
  margin,
  setMargin,
  align,
  imagesize,
  imageradius,
  selectedElement,
  setPadding,
  setSelectedElement
}) => {
  return (
    <div className='mt-2 flex flex-col'>
      {rows.map((row , rowIndex) => (
        <div key={row.id} className='flex flex-row my-8 relative '>
          {row.columns.map((column, index) => (
            <DroppableColumn
              key={index}
              rowId={row.id}
              columnIndex={index}
              rowIndex={rowIndex}
              item={column} // Ensure column is correctly passed
              headingStyle={headingStyle}
              heading={heading}
              colorStyle={colorStyle}
              bgColor={bgColor}
              onDropItem={onDropItem}
              padding={padding}
              margin={margin}
              align={align}
              imagesize={imagesize}
              setImageRadius={setImageRadius}
              setMargin={setMargin} 
              setPadding={setPadding} 
              imageradius={imageradius}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              setImageSize={setImageSize}
            />
          ))}
          <button
            className='bg-slate-200 m-1 absolute top-1 right-1 px-2 bg-opacity-75 rounded-full cursor-pointer'
            onClick={() => onRemoveRow(row.id)}
            style={{ transform: 'translate(-50%,-80%)' }}
          >
            <i className="fa-solid fa-xmark" style={{ color: 'black' }} ></i>
          </button>
        </div>
      ))}

    </div>
  );
};

export default RowSection;
