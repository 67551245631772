import React, { useCallback, useContext, useState } from 'react'
import Header from "../../../layouts/Header";
import { ThemeContext } from '../../../layouts/ThemeContext';
import RowSection from './RowSection';
import { usePage } from '../../../layouts/PageContext';
import Footer from '../../../layouts/Footer';

function NewPageScreen({ heading, click, container, handleContainer, headingStyle, colorStyle, padding, margin, align, imagesize, imageradius, setSelectedElement, selectedElement, bgColor, setPadding, setMargin, setImageRadius, grid, setImageSize }) {

  const { isLightMode } = useContext(ThemeContext);
  const [numRows, setNumRows] = useState(1);
  const [numColumns, setNumColumns] = useState(1);

  const [sections, setSections] = useState([]);
  const { formData, setFormData } = usePage()
  const handleAddSection = () => {
    setSections(!sections)
  };

  // useEffect(() => {
  //   document.title = 'Add New Page'
  // })

  const handleRow = useCallback((numColumns) => {
    const newRow = {
      id: Date.now(),
      columns: Array(numColumns).fill().map(() => ({
        items: [],
      })),
    };
    const updatedRows = [...formData.rows, newRow];
    setFormData((prevFormData) => ({
      ...prevFormData,
      rows: updatedRows,
    }));
  }, [formData.rows, setFormData]);

  const handleGrid = useCallback(() => {
    const newGridRows = Array(numRows).fill().map(() => ({
      id: Date.now() + Math.random(),
      columns: Array(numColumns).fill().map(() => ({
        items: [],
      })),
    }));

    const updatedRows = [...formData.rows, ...newGridRows];
    setFormData((prevFormData) => ({
      ...prevFormData,
      rows: updatedRows,
    }));
  }, [numRows, numColumns, formData.rows, setFormData]);


  const handleRemoveRow = useCallback((id) => {
    setFormData((prevFormData) => {
      const updatedRows = prevFormData.rows.filter(row => row.id !== id);
      return { ...prevFormData, rows: updatedRows };
    });
  }, [setFormData]);

  const handleDropItem = useCallback((item, rowId, columnIndex) => {
    setFormData(prevFormData => {
      const updatedRows = prevFormData.rows.map(row => {
        if (row.id === rowId) {
          const newColumns = [...row.columns];
          const column = newColumns[columnIndex];
          if (!column.items) {
            column.items = [];
          }
          const newItem = {
            ...item,
            content: item.content || '',
            style: {
              padding: { top: '0', right: '0', bottom: '0', left: '0' },
              margin: { top: '0', right: '0', bottom: '0', left: '0' },
              headingStyle: 'h1',
              color: 'white',
              size: { width: '100%', height: 'auto' },
              background: '',
              borderRadius: 0,
              fontSize : '10',
              boxShadow: '',
              textAlign: 'left'
            },
          };
          column.items.push(newItem);
          return { ...row, columns: newColumns };
        }
        return row;
      });

      return { ...prevFormData, rows: updatedRows };
    });
  }, [setFormData]);

  return (
    <>
      <div className="basis-[80%]  bg-cover bg-center bg-no-repeat w-full relative h-[93vh] overflow-auto " style={{
        backgroundImage: `url(${isLightMode ? "/assets/bg1.png" : "/assets/bg2.png"
          })`,
      }}>
        <Header />
        <RowSection
          rows={formData.rows}
          onRemoveRow={handleRemoveRow}
          onDropItem={handleDropItem}
          headingStyle={headingStyle}
          heading={heading}
          colorStyle={colorStyle}
          padding={padding}
          bgColor={bgColor}
          margin={margin}
          setMargin={setMargin}
          align={align}
          imagesize={imagesize}
          imageradius={imageradius}
          setImageSize={setImageSize}
          selectedElement={selectedElement}
          setPadding={setPadding}
          setImageRadius={setImageRadius}
          setSelectedElement={setSelectedElement}
        />
        <div className={`flex justify-around mt-8 pl-20 py-5 rounded-md relative ${sections ? 'hidden' : ''}`} style={{ border: 'dashed', borderWidth: '1px', borderColor: '#cccaca' }}>
          <>
            <label
              className='absolute top-2 right-1 px-2 bg-opacity-75 rounded-full cursor-pointer border-dashed group-hover:block ease-in duration-300'
              style={{ transform: 'translate(-50%,-80%)' }}
              onClick={handleAddSection}
            >
              <i className='fa-solid fa-xmark text-3xl'></i>
            </label>
            {[1, 2, 3].map(num => (
              <div key={num} className="flex cursor-pointer" onClick={() => handleRow(num)}>
                {Array(num).fill().map((_, idx) => (
                  <div key={idx} className="w-10 h-16 bg-gray-300 m-1 rounded-md text-center">
                    <i className="fa-solid fa-plus" style={{ position: 'relative', top: '41%', transform: 'translateY(-50%)' }}></i>
                  </div>
                ))}
              </div>
            ))}
          </>
        </div>
        <div className={`mt-10 mb-10 relative rounded border-gray-300 ${grid ? '' : 'hidden'} `} style={{ border: 'dashed', borderWidth: '1px' }}>
          <div className='text-center p-3 m-2'>
            <label style={{ color: 'white' }} className='m-2'>Enter Number of Rows : </label>
            <input type="number" value={numRows} onChange={(e) => setNumRows(parseInt(e.target.value, 10) || 1)} className='bg-gray-200 rounded-md p-2 w-[5%] outline-none' />
            <label style={{ color: 'white' }} className='m-2'>Enter Number of Columns :</label>
            <input type="number" value={numColumns} onChange={(e) => setNumColumns(parseInt(e.target.value, 10) || 1)} className='bg-gray-200 rounded-md p-2 w-[5%] outline-none' />
          </div>
          <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2% auto', color: 'white' }} className='bg-[#ff7a50]  font-bold py-3 px-6 rounded-xl transition duration-300' onClick={handleGrid}>Add New Grid</button>
        </div>

        <div className={`mt-10 mb-10 relative rounded border-gray-300 ${container ? '' : 'hidden'} `} style={{ border: 'dashed', borderWidth: '1px' }}>
          <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2% auto', color: 'white' }} className='bg-[#ff7a50]  font-bold py-3 px-6 rounded-xl transition duration-300' onClick={handleAddSection}>Add New Section</button>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default NewPageScreen