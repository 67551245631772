import React, { useState, createContext, useContext } from 'react';

// Create a context for fileMap
const FileMapContext = createContext();

// Create a custom hook for easy access to fileMap context
export const useFileMap = () => useContext(FileMapContext);

// Create a provider component
export const FileMapProvider = ({ children }) => {
  const [fileMap, setFileMap] = useState({});

  return (
    <FileMapContext.Provider value={{ fileMap, setFileMap }}>
      {children}
    </FileMapContext.Provider>
  );
};
