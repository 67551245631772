import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Helpers from '../../../../Config/Helpers'
import { useParams } from 'react-router-dom'
import Header from '../../../../layouts/Header'
import { ThemeContext } from '../../../../layouts/ThemeContext'
import Footer from '../../../../layouts/Footer'
import Loader from '../../../../layouts/Loader'

function PreviewScreen() {
  const [data, setData] = useState([])
  const [loading, setIsLoading] = useState(true)
  const { isLightMode } = useContext(ThemeContext);
  const { slug } = useParams()
  const getData = async () => {
    // setIsLoading(true)
    const response = await axios.get(`${Helpers.apiUrl}page/show/${slug}`)
    setData(response.data)
    // console.log(response.data)
    // setIsLoading(false)
  }
  const renderHeading = (content, headingStyle, style) => {
    const styles = {
      padding: `${style?.padding?.top ?? 0}px ${style?.padding?.right ?? 0}px ${style?.padding?.bottom ?? 0}px ${style?.padding?.left ?? 0}px`,
      margin: `${style?.margin?.top ?? 0}px ${style?.margin?.right ?? 0}px ${style?.margin?.bottom ?? 0}px ${style?.margin?.left ?? 0}px`,
      color: style?.color ?? 'inherit',
      textAlign: style?.alignment ?? 'left',
      fontSize: style.fontSize
    };

    switch (headingStyle) {
      case 'h1':
        return <h1 className="text-3xl font-bold" style={styles}>{content}</h1>;
      case 'h2':
        return <h2 className="text-2xl font-bold" style={styles}>{content}</h2>;
      case 'h3':
        return <h3 className="text-xl font-bold" style={styles}>{content}</h3>;
      default:
        return <div style={styles}>{content}</div>;
    }
  };

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data && data.name) {
      document.title = data.name
    }
  }, [data])
  const renderImage = (content, style) => {
    const styles = {
      display: 'flex',
      justifyContent: 'center',
      width: `${style?.size?.width ?? 100}%`,
      height: `${style?.size?.height ?? 'auto'}`,
      borderRadius: `${style?.borderRadius ?? 0}px`,
      margin: '0 auto'
    };

    return <img src={`${Helpers.basePath}/storage/${content}`} alt="content" style={styles} />;
  };

  const renderTextarea = (content, style) => {
    const styles = {
      padding: `${style?.padding?.top ?? 0}px ${style?.padding?.right ?? 0}px ${style?.padding?.bottom ?? 0}px ${style?.padding?.left ?? 0}px`,
      margin: `${style?.margin?.top ?? 0}px ${style?.margin?.right ?? 0}px ${style?.margin?.bottom ?? 0}px ${style?.margin?.left ?? 0}px`,
      color: style?.color ?? 'inherit',
      textAlign: style?.alignment ?? 'left',
      fontSize: style.fontSize
    };

    return <p style={styles}>{content}</p>;
  };

  const renderButton = (content, style) => {
    const containerStyles = {
      textAlign: style?.alignment ?? 'left',
      margin: `${style?.margin?.top ?? 0}px ${style?.margin?.right ?? 0}px ${style?.margin?.bottom ?? 0}px ${style?.margin?.left ?? 0}px`,
    };
    const buttonStyles = {
      // padding: `${style?.padding?.top ?? 5}px ${style?.padding?.right ?? 40}px ${style?.padding?.bottom ?? 5}px ${style?.padding?.left ?? 40}px`,
      padding: '5px 40px 5px 40px',
      color: style?.color ?? '#fff',
      backgroundColor: `${style?.background ?? '#ff7a50'}`,
      borderRadius: `${style?.borderRadius ?? 0}px`,
      boxShadow: style?.boxShadow ?? 'none',
      display: 'inline-block',
      fontWeight: 'bold',
      fontSize: style.fontSize
    };
    return (
      <div style={containerStyles}>
        <button style={buttonStyles}>{content}</button>
      </div>
    );
  };


  const renderCard = (content, type, heading, paragraph, style) => {
    const containerStyles = {
      textAlign: style?.alignment ?? 'left',
      margin: `${style?.margin?.top ?? 0}px ${style?.margin?.right ?? 0}px ${style?.margin?.bottom ?? 0}px ${style?.margin?.left ?? 0}px`,
      backgroundColor: style?.background ?? 'none',
      borderRadius: `${style?.borderRadius ?? 0}px`,
      boxShadow: style?.boxShadow ?? 'none',
      flexGrow: 1,
      padding: `${style?.padding?.top ?? 0}px ${style?.padding?.right ?? 0}px ${style?.padding?.bottom ?? 0}px ${style?.padding?.left ?? 0}px`
    };

    const imageStyles = {
      width: `${style?.size?.width ?? 100}%`,
      height: `${style?.size?.height ?? 'auto'}`,
      borderRadius: `${style?.radius ?? 0}px`,
      padding: `${style?.padding ?? 0}px`,
      margin: '0 auto'
    };
    // console.log(style.padding);

    const paragraphStyles = {
      color: style?.color ?? 'white',
      fontSize: style.fontSize,
      padding: `${style?.padding?.top}px ${style?.padding?.right}px ${style?.padding?.bottom}px ${style?.padding?.left}px`,
      margin: `${style?.margin?.top ?? 0}px ${style?.margin?.right ?? 0}px ${style?.margin?.bottom ?? 0}px ${style?.margin?.left ?? 0}px`,
    };

    return (
      <div style={containerStyles}>
        {type === 'card' && (
          <>
            {content && (
              <img
                src={`${Helpers.basePath}/storage/${content}`}
                alt="content"
                style={imageStyles}
              />
            )}
            {heading && renderHeading(heading, style.headingStyle, style)}
            {paragraph && <p style={paragraphStyles}>{paragraph}</p>}
          </>
        )}
      </div>
    );
  };

  const renderColumnContent = (columnData) => {
    if (Array.isArray(columnData.items) && columnData.items.length > 0) {
      return columnData.items.map((item, index) => {
        switch (item.type) {
          case 'heading':
            return renderHeading(item.content, item.style.headingStyle, item.style);
          case 'image':
            return renderImage(item.content, item.style);
          case 'textarea':
            return renderTextarea(item.content, item.style);
          case 'button':
            return renderButton(item.content, item.style, item.cardStyle);
          case 'card':
            return renderCard(item.content, item.type, item.heading, item.paragraph, item.style, item.cardStyle);
          default:
            return <div key={index}>{item.content}</div>;
        }
      });
    } else {
      return <div>No content available</div>;
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? <Loader /> :
        <div className="bg-cover bg-center bg-no-repeat h-screen w-full relative"
          style={{
            backgroundImage: `url(${isLightMode ? "/assets/bg1.png" : "/assets/bg2.png"
              })`,
          }}>
          <Header />
          <div>
            {data ? (
              <div>
                <div className="container mx-auto p-5 m-5">
                  {data.rows && data.rows.length > 0 ? (
                    data.rows.map((row) => (
                      <div key={row.id} className="row block lg:flex flex-wrap mx-5">
                        {row.columns && row.columns.length > 0 ? (
                          row.columns.map((column) => {
                            const columnData = JSON.parse(column.data);
                            return (
                              <div
                                key={column.id}
                                className="flex-1 p-5 " // Flex-grow with minimum width
                              >
                                <div className="content">
                                  {renderColumnContent(columnData)}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No columns available</p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No rows available</p>
                  )}
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )}

          </div>
          <Footer />
        </div>
      }
    </div>
  )
}

export default PreviewScreen