import React, { useEffect, useState } from 'react'
import Sidebar from '../../Components/Sidebar'
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import PublishHeaderDropdown from './PublishHeaderDropdown';

function HeaderSection() {
    const [data, setData] = useState([]);

    // Define custom pages
    const customPages = [
        { id: '1', name: 'Home', slug: 'home', is_publish: 1, is_header: 1 , is_custom: 1},
        { id: '2', name: 'Download', slug: 'download', is_publish: 1, is_header: 1 , is_custom: 1},
        { id: '3', name: 'FAQs', slug: 'faqs', is_publish: 1, is_header: 1 , is_custom: 1},
        { id: '4', name: 'Support', slug: 'support', is_publish: 1, is_header: 1 , is_custom: 1},
        { id: '5', name: 'News & Article', slug: 'news-article', is_publish: 1, is_header: 1 , is_custom: 1},
    ];

    // Fetch data from the database 
    const getData = async () => {
        const response = await axios.get(`${Helpers.apiUrl}page/show`);
        const dbPages = response.data.data;
        console.log(response.data.data)

        // Check if any custom pages are missing from the database and add them via the `update-header-status` API
        customPages.forEach(async (customPage) => {
            const exists = dbPages.some(page => page.slug === customPage.slug);
            if (!exists) {
                try {
                    // Use the update-header-status API to add the custom page to the database
                    await axios.post(`${Helpers.apiUrl}update-header-status/${customPage.slug}`, {
                        is_header: customPage.is_header,  // Default value for is_header
                        is_custom: customPage.is_custom,  // Ensure is_custom is also sent
                    });
                    console.log(`Custom page ${customPage.name} added to the database.`);
                } catch (error) {
                    console.error(`Failed to add custom page ${customPage.name}`, error);
                }
            }
        });

        // Merge database pages with custom pages
        setData([ ...dbPages]);
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <div>
            <div id="kt_app_wrapper" className="app-wrapper flex-column flex-row-fluid">
                <Sidebar />
                <div className="card mb-5 mb-xl-8 bg-slate-200" style={{ marginTop: "-4%" }}>
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">All Pages</span>
                        </h3>
                    </div>
                    <div className="card-body py-3 m-5 rounded bg-gray-100">
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th className="min-w-10px">#</th>
                                        <th className="min-w-700px">Page Name</th>
                                        <th className="min-w-50px text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((page, index) => (
                                        <tr key={customPages.some(customPage => customPage.slug === page.slug) ? `custom-${page.slug}` : page.slug}>
                                            <td>{index + 1}</td>
                                            <td className="">
                                                <div className="d-flex flex-column w-100 me-2">
                                                    <div className="d-flex flex-start ">
                                                        <span className=" d-flex text-muted me-2 fs-7 fw-bold">
                                                            <p>{page.name}</p>
                                                            {/* Conditionally render badge for custom or created */}
                                                            { page.is_custom === '1' ? (
                                                                <span className="badge bg-primary ml-3" style={{ color:'white' }}>Custom</span>
                                                            ) : (
                                                                <span className="badge bg-success ml-3" style={{ color:'white' }}>Created</span>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="">
                                                <div className="d-flex flex-column w-100 me-2">
                                                    <div className="d-flex flex-center ">
                                                        <span className="text-muted me-2 fs-7 fw-bold">
                                                            <PublishHeaderDropdown data={page} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderSection
