import React from 'react'

function Button({subItem , handleFeatureChange}) {
    return (
        <div
            style={{
                textAlign: subItem.style.alignment,
                padding: `${subItem.style.margin.top}px ${subItem.style.margin.right}px ${subItem.style.margin.bottom}px ${subItem.style.margin.left}px`,
            }}
        >
            <input
                type="text" autoComplete="off"
                className="bg-[#FF7A50] outline-none font-bold transition duration-300 text-center placeholder-gray-100"
                placeholder="Enter Button Text Here"
                onChange={handleFeatureChange}
                name="content"
                value={subItem.content}
                style={{
                    color: subItem.style.color || 'white',
                    borderRadius: `${subItem.style.borderRadius}px`,
                    padding: `${subItem.style.padding.top}px ${subItem.style.padding.right}px ${subItem.style.padding.bottom}px ${subItem.style.padding.left}px`,
                    background: subItem.style.background,
                    margin: `${subItem.style.margin.top}px ${subItem.style.margin.right}px ${subItem.style.margin.bottom}px ${subItem.style.margin.left}px`,
                    boxShadow: subItem.style.boxShadow,
                    fontSize: subItem.style.fontSize
                }}
            />
        </div>
    )
}

export default Button
