import { React, useEffect } from 'react'
import { useState } from "react";
import { usePage } from '../../../../layouts/PageContext';
import Helpers from '../../../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from 'react-router-dom';
const Header = () => {
  const { formData, setFormData } = usePage();
  const [loading, setLoading] = useState(false)
  const { slug } = useParams();
  // const handlePublish = async () => {
  //   console.log('Publishing formData:', formData);
  //   setLoading(true)
  //   const createFormData = (data, formData = new FormData(), parentKey = '') => {
  //     if (typeof data === 'object' && data !== null && !(data instanceof File)) {
  //       Object.keys(data).forEach(key => {
  //         createFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
  //       });
  //     } else {
  //       formData.append(parentKey, data);
  //     }
  //     return formData;
  //   };

  //   const data = createFormData(formData);
  //   try {
  //     const response = await axios.post(`${Helpers.apiUrl}page/store`, data, {
  //       headers: {
  //         "Content-Type": 'multipart/form-data',
  //       },
  //     });
  //     Helpers.toast("success", 'Data Stored')
  //     setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //     console.error('Error storing data:', error);
  //     Helpers.toast("error", error.response.data.message);
  //   }
  // };
  // const handlePublish = async () => {
  //   setLoading(true);

  //   const createFormData = (data, formData = new FormData(), parentKey = '') => {
  //     if (typeof data === 'object' && data !== null && !(data instanceof File)) {
  //       Object.keys(data).forEach(key => {
  //         createFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
  //       });
  //     } else {
  //       formData.append(parentKey, data);
  //     }
  //     return formData;
  //   };

  //   const data = createFormData(formData);

  //   // Ensure 'rows' exists and iterate over it
  //   if (formData.rows && Array.isArray(formData.rows)) {
  //     formData.rows.forEach((row, rowIndex) => {
  //       if (row.columns && Array.isArray(row.columns)) {
  //         row.columns.forEach((column, columnIndex) => {
  //           if (column.items && Array.isArray(column.items)) {
  //             column.items.forEach((item, itemIndex) => {
  //               if (item.file) {
  //                 // Append file if it exists
  //                 data.append(`rows[${rowIndex}][columns][${columnIndex}][items][${itemIndex}][file]`, item.file);
  //               }
  //             });
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     console.error('formData.rows is either undefined or not an array.');
  //     setLoading(false);
  //     return;
  //   }

  //   const url = id ? `${Helpers.apiUrl}page/update/${id}` : `${Helpers.apiUrl}page/store`;

  //   try {
  //     const response = await axios.post(url, data, {
  //       headers: {
  //         "Content-Type": 'multipart/form-data',
  //       },
  //     });
  //     Helpers.toast("success", 'Data Stored');
  //     setLoading(false);
  //     console.log('FormData to send:', data);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error storing data:', error);
  //     Helpers.toast("error", error.response.data.message);
  //   }
  // };

  const handlePublish = async () => {
    setLoading(true);
  
    const createFormData = (data, formData = new FormData(), parentKey = '') => {
      if (typeof data === 'object' && data !== null && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          createFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
        });
      } else if (data instanceof File) {
        formData.append(parentKey, data);
      } else {
        formData.append(parentKey, data);
      }
      return formData;
    };
    
  
    // Clone the formData without the files (for JSON fields)
    const clonedData = JSON.parse(JSON.stringify(formData));
  
    // Convert JSON data to FormData
    const data = createFormData(clonedData);
  
    // Ensure files are appended correctly
    if (formData.rows && Array.isArray(formData.rows)) {
      formData.rows.forEach((row, rowIndex) => {
        if (row.columns && Array.isArray(row.columns)) {
          row.columns.forEach((column, columnIndex) => {
            if (column.items && Array.isArray(column.items)) {
              column.items.forEach((item, itemIndex) => {
                if (item.file && item.file instanceof File) {
                  // Ensure file is correctly appended under the correct key
                  data.append(`rows[${rowIndex}][columns][${columnIndex}][items][${itemIndex}][file]`, item.file);
                }
              });
            }
          });
        }
      });
    }
  
    const url = slug ? `${Helpers.apiUrl}page/update/${slug}` : `${Helpers.apiUrl}page/store`;
  
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": 'multipart/form-data',
        },
      });
      Helpers.toast("success", 'Data Stored');
      console.log("store", response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error storing data:', error);
      Helpers.toast("error", error.response?.data?.message || "An error occurred");
    }
  };
  

  useEffect(() => {
    if (slug) {
      fetchPageData(slug);
    }
  }, [slug]);

  const fetchPageData = async (slug) => {
    try {
      setLoading(true);
      const response = await axios.get(`${Helpers.apiUrl}page/show/${slug}`);
      const pageData = response.data;
      setFormData(pageData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching page data:', error);
    }
  };
  return (
    <div>
      <div className="bg-[#222626] flex justify-between">
        <Link to='/admin/dashboard' className="btn" style={{ color: 'white' }}>
          <i className="fa-solid fa-arrow-left"></i> Back
        </Link>
        <input
          type="text"
          placeholder="Enter Page Title"
          className="outline-none p-2 rounded-md bg-[#2e2e2e] text-center"
          style={{ color: 'white' }}
          value={formData.name || ''}
          onChange={(e) => setFormData({ ...formData, name: e.target.value, slug: e.target.value.toLowerCase().replace(/\s+/g, '-') })}
        />
        <div className="flex items-center justify-between" style={{ marginRight: '3%' }} >
          <button className="btn" style={{ color: "white", background: "green" }} onClick={handlePublish}>
            {loading ? '' : 'Publish'}
            <ClipLoader
              color="white"
              loading={loading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header